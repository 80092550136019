// eslint-disable-next-line import/prefer-default-export
export const analyticsClient = {
  page: (...args) => {
    if (window.analytics) {
      window.analytics.page(...args);
    }
  },
  track: (...args) => {
    if (window.analytics) {
      window.analytics.track(...args);
    }
  },
  trackLink: (...args) => {
    if (window.analytics) {
      window.analytics.trackLink(...args);
    }
  },
  identify: (...args) => {
    if (window.analytics) {
      window.analytics.identify(...args);
    }
  },
};
