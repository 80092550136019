import React from 'react';
import { analyticsClient } from '../client/analytics';
import { reveal } from '../client/clearbit';

export default class WithPage extends React.Component {
  componentDidMount() {
    const source = decodeURIComponent(new URL(window.location.toString()).searchParams.get('source'));
    analyticsClient.page({ new_website: true, source });
    reveal().then((values) => {
      analyticsClient.identify({
        revealResults: values,
      });
    });
  }

  render() {
    return this.props.children;
  }
}
