// React
import React from 'react';

// Next
import App from 'next/app';
import Head from 'next/head';

// Scripts
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Context
import FormContextProvider from '../context/FormContext';
import UserContextProvider from '../context/UserContext';

// Add inline svg's
import '../components/Svg/Svg';

// Import styles
import '../styles/base/global.scss';

// Helper components
import WithPage from '../helpers/withPage';

// eslint-disable-next-line no-underscore-dangle
if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
    appVersion: process.env.COMMIT_REF,
  });
}
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const mutinyClientUrl = process.env.MUTINY_CLIENT_URL;

class MyApp extends App {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // static async getInitialProps(appContext) {
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  //   const appProps = await App.getInitialProps(appContext);
  //
  //   return { ...appProps }
  // }
  render() {
    const { Component, pageProps } = this.props;

    return (
      <ErrorBoundary>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
          <link rel="manifest" href="/images/favicon/site.webmanifest" />
          <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#3A0068" />
          <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
          <meta name="msapplication-TileColor" content="#cff6ff" />
          <meta name="msapplication-TileImage" content="/images/favicon/mstile-144x144.png" />
          <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
          <meta name="theme-color" content="#3A0068" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
          {mutinyClientUrl ? <script src={mutinyClientUrl} /> : null}
        </Head>
        <FormContextProvider>
          <UserContextProvider>
            <WithPage>
              <Component {...pageProps} />
            </WithPage>
          </UserContextProvider>
        </FormContextProvider>
        <script
          type="text/javascript"
          key="segment-snippet"
          dangerouslySetInnerHTML={{
            __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
            analytics.load('${process.env.SEGMENT_CLIENT_WRITE_KEY}');
          }}();`,
          }}
          async
        />
        <script src="https://js.chilipiper.com/marketing.js" type="text/javascript" />
        {/* <script
          type="text/javascript"
          key="intercom-script"
          dangerouslySetInnerHTML={{
            __html: `
          var APP_ID = "${intercomAppId}";
          window.intercomSettings = {
            app_id: APP_ID
          };

          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload', l);}else{w.addEventListener('load', l, false);}}})();
          `,
          }}
          async
        /> */}

        {/* Do not add google analytics in development */}
        {process.env.NODE_ENV !== 'development' && (
          <>
            <script async key="gtag-script" src="https://www.googletagmanager.com/gtag/js?id=AW-705809625" />
            <script type="text/javascript" key="adwords-script" src="/javascripts/adwords.js" async />
          </>
        )}
      </ErrorBoundary>
    );
  }
}

export default MyApp;
