import 'whatwg-fetch';
import { dig } from '../helpers/dig';

function get(url, key) {
  return fetch(url, {
    headers: {
      Authorization: `Basic ${btoa(`${key || process.env.CLEARBIT_API_KEY}:`)}`,
    },
  }).then((response) => response.json());
}

/**
 * Performs a clearbit combined enrichment on an email.
 *
 * https://clearbit.com/docs#enrichment-api-combined-api
 */
export function enrich(email) {
  return get(`https://person-stream.clearbit.com/v2/combined/find?email=${email}`);
}

export function enrichCompany(domain) {
  return get(`https://company.clearbit.com/v2/companies/find?domain=${domain}`);
}

export function reveal() {
  return get('https://reveal.clearbit.com/v1/companies/reveal', process.env.CLEARBIT_PUBLISHABLE_API_KEY);
}

export function triggerWithTimeout(asyncCallback, onTimeout, onComplete, onCompleteBeforeTimeout) {
  let didTimeout = false;
  const timeoutId = setTimeout(() => {
    didTimeout = true;
    if (onTimeout) {
      onTimeout();
    }
  }, 500);

  asyncCallback().then((result) => {
    if (!didTimeout) {
      clearTimeout(timeoutId);
      if (onCompleteBeforeTimeout) {
        onCompleteBeforeTimeout(result);
      }
    }
    if (onComplete) {
      onComplete(result);
    }
  });
}

const CLEARBIT_BUSINESS_CLASSIFICATIONS = [
  'B2B',
  'B2C',
  'E-Commerce',
  'Enterprise',
  'ISP',
  'Marketplace',
  'Mobile',
  'SAAS',
];

/**
 * Munge enrich output into a bunch of key/value pairs to send to hubspot
 */
export function collectCompanyFields(enrichResult) {
  return {
    name: dig(enrichResult, 'company', 'name'),
    industry: dig(enrichResult, 'company', 'category', 'industry'),
    sub_industry: dig(enrichResult, 'company', 'category', 'subIndustry'),
    company_tags: (dig(enrichResult, 'company', 'tags') || [])
      .filter((tag) => !CLEARBIT_BUSINESS_CLASSIFICATIONS.includes(tag))
      .join(';'),
    company_classification: (dig(enrichResult, 'company', 'tags') || [])
      .filter((tag) => CLEARBIT_BUSINESS_CLASSIFICATIONS.includes(tag))
      .join(';'),
    country: dig(enrichResult, 'company', 'geo', 'country'),
    state: dig(enrichResult, 'company', 'geo', 'state'),
    city: dig(enrichResult, 'company', 'geo', 'city'),
    linkedin_company_page: dig(enrichResult, 'company', 'linkedin', 'handle'),
    phone: dig(enrichResult, 'company', 'phone'),
    employeesrange: dig(enrichResult, 'company', 'metrics', 'employeesRange'),
    funding_amount: dig(enrichResult, 'company', 'metrics', 'raised'),
    us_alexa_rank: dig(enrichResult, 'company', 'metrics', 'alexaUsRank'),
    global_alexa_rank: dig(enrichResult, 'company', 'metrics', 'alexaGlobalRank'),
  };
}

/**
 * Munge enrich output into a bunch of key/value pairs to send to hubspot
 */
export function collectPersonFields(enrichResult) {
  return {
    firstname: dig(enrichResult, 'person', 'name', 'givenName'),
    lastname: dig(enrichResult, 'person', 'name', 'familyName'),
    contact_linkedin: dig(enrichResult, 'person', 'linkedin', 'handle'),
    jobtitle: dig(enrichResult, 'person', 'employment', 'title'),
    role: dig(enrichResult, 'person', 'employment', 'role'),
    seniority: dig(enrichResult, 'person', 'employment', 'seniority'),
  };
}
