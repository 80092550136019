import { createContext, useState, useEffect } from 'react';
import { withRouter } from 'next/router';
import { reveal, triggerWithTimeout } from '../client/clearbit';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [revealResult, setRevealResult] = useState(null);
  const [loadingReveal, setLoadingReveal] = useState(true);
  const [referrer, setReferrer] = useState('');

  // Trigger reveal and set it in context after mounting
  // Attempts to load for .5 a second before giving up with reveal
  useEffect(() => {
    const onTimeout = () => setLoadingReveal(false);
    const onCompleteBeforeTimeout = (result) => {
      setLoadingReveal(false);
      setRevealResult(result);
    };

    triggerWithTimeout(reveal, onTimeout, null, onCompleteBeforeTimeout);

    // Gets the referrer to the website, document.referrer will change as the user navigates through our pages
    // Capturing this now lets us send the inital referrer if the user enters the signup flow
    setReferrer(document.referrer);
  }, []);

  return (
    <UserContext.Provider
      value={{
        revealResult,
        setRevealResult,
        loadingReveal,
        setLoadingReveal,
        referrer,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default withRouter(UserContextProvider);
