import { createContext, useState } from 'react';
import { withRouter } from 'next/router';

export const FormContext = createContext();

const FormContextProvider = (props) => {
  // @todo consider to use useReducer hook
  // https://reactjs.org/docs/hooks-reference.html#usereducer
  const [step, setStep] = useState(1);
  const [foundEmailMatch, setFoundEmailMatch] = useState(false);
  const [latestEnrichResult, setLatestEnrichResult] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [preEnteredEmail, setPreEnteredEmail] = useState(null);

  return (
    <FormContext.Provider
      value={{
        step,
        setStep,
        foundEmailMatch,
        setFoundEmailMatch,
        latestEnrichResult,
        setLatestEnrichResult,
        qualification,
        setQualification,
        preEnteredEmail,
        setPreEnteredEmail,
        totalSteps: 5,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default withRouter(FormContextProvider);
